<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="预算编号">
              {{ user?.code }}
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="填报单位">
              {{ user?.deptName }}
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="填报人">
              {{ user?.creatorName }}
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="填报时间">
              {{ user?.createAt }}
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="费用项清单"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <Subject :list="list" :showBtn="false"/>
            </a-form-item>
          </a-col>

        </a-row>
        <div class="center">
            <a-space>
              <a-button @click="$close($route.path)">关闭</a-button>
            </a-space>
          </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";
import Subject from "./components/subject.vue";
import organization from "@/mixins/organization";
function getDetail(id) {
  return request({
    url: "/office-service/quality/fee/budget/detail/" + id,
  });
}
export default {
  name: "technicalQualityScoreDetail",
  mixins: [organization],
  components: {
    Subject,
  },

  data() {
    return {
      code: '',
      form: this.$form.createForm(this),
      user: '',
      list: [],
      users: [],
      judges: [],

      loading: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    getDetail(id).then(res => {
      this.user = res;
      this.list = res.itemList;
    });
  },
  methods: {
  },
};
</script>


<style lang="less" scoped>
.container {
  padding: 12px;
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>